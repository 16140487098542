import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import {
  FulfilmentMethod,
  Product,
  ProductList,
  ProductSort,
  Promoproductslider,
  PromoproductsliderProductListArgs,
  QueryPageArgs,
  SponsoredProducts,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  Orientation,
  ProductsShowcase,
  ProductsShowcaseProps,
} from '@thg-commerce/enterprise-widget-products-showcase'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { styled } from '@thg-commerce/gravity-theme'
import { ProductBlockProps } from '@thg-commerce/enterprise-components'
import {
  i18n,
  useHorizonSessionSettings,
  useSiteConfig,
  useTheme,
} from '@thg-commerce/enterprise-core'
import { getRrp } from '@thg-commerce/enterprise-pricing/src'

import { PRODUCTS_SHOWCASE_LIST_QUERY } from './ProductsShowcaseQuery'
import { BaseWidgetProps } from '../types'
import { getPowerReviewGroups } from '@thg-commerce/enterprise-components/src/PowerReview/PowerReview'
import { ProductBlock } from '@thg-commerce/enterprise-graphql/aurora-schema'

export enum ProductOptionKeys {
  COLOUR = 'Colour',
  FLAVOUR = 'Flavour',
  SIZE = 'Size',
}

export type ProductsShowcaseRendererProps =
  | ((BaseWidgetProps &
      Omit<Promoproductslider, 'query'> & {
        orientation?: Orientation
      }) & { widgetIndex: number })
  | (SponsoredProducts & {
      widgetIndex: number
      productBlockOne?: ProductBlock
      productBlockTwo?: ProductBlock
    })

export type ProductsShowcaseArgs = QueryPageArgs &
  PromoproductsliderProductListArgs &
  Omit<Widget, 'query'>

const StyledProductsShowcase = styled(ProductsShowcase)`
  margin: 0 auto;
  max-width: ${(props) => props.theme.site.siteWidth};
`

export const ProductsShowcaseRenderer = (
  props: ProductsShowcaseRendererProps,
) => {
  const {
    showPdpLinkWhenOutOfStock,
    hideProductListImageRollover,
    hideProductShowCaseMarketedSpecialOfferBadge,
  } = useSiteConfig()

  const { lazy, fetchPriority } =
    props.widgetIndex > 1
      ? { lazy: true, fetchPriority: 'auto' as 'auto' }
      : { lazy: false, fetchPriority: 'high' as 'high' }

  const i18nText = {
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
    rrpText: i18n('product.price.rrp.text'),
    swatchTooltipUnavailable: i18n(
      'product.productoptions.swatch.tooltip.unavailable.text',
    ),
    swatchTooltipCloseButton: i18n(
      'product.productoptions.swatch.tooltip.close.text',
    ),
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: i18n('product.list.clickandcollect.text'),
        isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
      },
      homeDelivery: {
        isAvailable: i18n('product.list.delivery.available'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('clickandcollect.store.availability.outofstock'),
        nextDayDelivery: i18n('product.list.item.nextdaydelivery'),
        oneManDelivery: i18n(
          'product.list.fulfilment.1man.nextdaydeliveryavailable',
        ),
        outOfGaugeDelivery: i18n(
          'product.list.fulfilment.outofgauge.nameddaydeliveryavailable',
        ),
      },
      storeDelivery: {
        isAvailable: i18n('product.delivery.store.available.text'),
      },
      orderInStore: {
        isAvailable: i18n(
          'product.item.fulfilment.orderinstore.available.text',
        ),
      },
    },
  }

  const dynamicDelivery = (weightGroups) => {
    return i18n(`product.item.fulfilment.${weightGroups}`)
  }

  if (props.__typename === 'sponsoredProducts') {
    if (
      !(props.productBlockOne && props.productBlockTwo) &&
      !(props.ProductOne && props.ProductTwo)
    ) {
      return null
    }

    const productBlocks = [
      props.productBlockOne
        ? {
            ...props.productBlockOne,
            title: {
              value: props.productBlockOne.title,
            },
            price: {
              ...props.productBlockOne.price,
              rrpText: i18nText.rrpText,
            },
            review: props.productBlockOne.reviews && {
              starRating: props.productBlockOne.reviews?.averageScore,
              numberOfReviews: props.productBlockOne.reviews?.total,
            },
            marketedSpecialOffer: props.productBlockOne.marketedSpecialOffer
              ? {
                  ...props.productBlockOne.marketedSpecialOffer,
                  i18nText,
                  title: props.productBlockOne.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockOne.marketedSpecialOffer.descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
          }
        : null,
      props.productBlockTwo
        ? {
            ...props.productBlockTwo,
            title: {
              value: props.productBlockTwo.title,
            },
            price: {
              ...props.productBlockTwo.price,
              rrpText: i18nText.rrpText,
            },
            review: props.productBlockTwo.reviews && {
              starRating: props.productBlockTwo.reviews?.averageScore,
              numberOfReviews: props.productBlockTwo.reviews?.total,
            },
            marketedSpecialOffer: props.productBlockTwo.marketedSpecialOffer
              ? {
                  ...props.productBlockTwo.marketedSpecialOffer,
                  i18nText,
                  title: props.productBlockTwo.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockTwo.marketedSpecialOffer.descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
          }
        : null,
    ].filter((productBlock) => productBlock !== null)

    const productListData: ProductBlockProps[] =
      props.ProductOne && props.ProductTwo
        ? [props.ProductOne.product!, props.ProductTwo.product!].map(
            (product, index) => {
              const productParent =
                props[`Product${index === 0 ? 'One' : 'Two'}`]
              const productPrice =
                product.defaultVariant?.price || product.cheapestVariant?.price
              const swatchProps = product.options
                .find((option) => option.key === ProductOptionKeys.COLOUR)
                ?.choices.reduce(
                  (swatches: NonNullable<ColourChoice[]>, colourChoice) => {
                    const href = product.variants.find(
                      (variant) =>
                        variant.inStock &&
                        variant.choices.find(
                          (choice) =>
                            choice.optionKey === ProductOptionKeys.COLOUR &&
                            choice.key === colourChoice.key,
                        ),
                    )?.product?.url
                    href &&
                      colourChoice.colour &&
                      swatches.push({
                        href,
                        colour: colourChoice.colour,
                        ...colourChoice,
                      })

                    return swatches
                  },
                  [],
                )

              const iconsAvailability = productParent?.eligibleForFulfilmentMethods && {
                homeDelivery: Boolean(
                  productParent.eligibleForFulfilmentMethods?.includes(
                    FulfilmentMethod.HomeDelivery,
                  ),
                ),
                storeAvailable: Boolean(
                  productParent.eligibleForFulfilmentMethods?.includes(
                    FulfilmentMethod.CollectInStore,
                  ),
                ),
                storeDelivery: Boolean(
                  productParent.eligibleForFulfilmentMethods?.includes(
                    FulfilmentMethod.DeliverToStore,
                  ),
                ),
              }

              return {
                sku: productParent?.sku.toString(),
                title: { value: productParent?.title },
                externalIdentifier:
                  productParent?.externalIdentifier || undefined,
                url: product.url,
                fulfilmentMethodIconsProps: {
                  iconsAvailability: iconsAvailability || undefined,
                  isCheckStock: productParent?.isCheckStock,
                  isOrderInStore: productParent?.isOrderInStore,
                  inStock: productParent?.inStock,
                  i18nText: {
                    ...i18nText.fulfilmentMethodsText,
                    homeDelivery: {
                      ...i18nText.fulfilmentMethodsText.homeDelivery,
                      datedDelivery: i18n(
                        'basket.item.fulfilment.leadtime',
                        productParent.leadTime?.toString(),
                      ),
                      dynamicDelivery: dynamicDelivery(
                        productParent.weightGroups?.[0]?.toLowerCase(),
                      ),
                    },
                  },
                  leadTime: productParent.leadTime,
                  weightGroups: productParent.weightGroups,
                  isBookable: productParent.isBookable,
                  inStockLocations: productParent.inStockLocations,
                },
                price: productPrice?.price.displayValue
                  ? {
                      price: {
                        defaultPrice: productPrice.price.displayValue,
                        minPrice:
                          product.cheapestVariant?.price?.price.displayValue,
                        maxPrice:
                          product.mostExpensiveVariant?.price?.price
                            .displayValue,
                      },
                      rrp: {
                        defaultPrice: getRrp(productPrice),
                        minPrice:
                          product.cheapestVariant?.price?.rrp &&
                          getRrp(product.cheapestVariant.price),
                        maxPrice:
                          product.mostExpensiveVariant?.price?.rrp &&
                          getRrp(product.mostExpensiveVariant.price),
                      },
                      rrpText: i18nText.rrpText,
                    }
                  : undefined,
                image: {
                  lazy,
                  fetchPriority,
                  urls: {
                    largeProduct:
                      product.images?.[0]?.largeProduct ||
                      productParent?.images?.[0]?.largeProduct,
                  },
                  isAmp: false,
                },
                hoverImage:
                  !hideProductListImageRollover && product.images.length > 1
                    ? {
                        urls: {
                          largeProduct:
                            product.images?.[1]?.largeProduct ||
                            productParent?.images?.[1]?.largeProduct,
                        },
                        isAmp: false,
                        lazy: typeof window !== 'undefined' ? false : true,
                        fetchPriority: 'low',
                      }
                    : undefined,
                review: product.reviews && {
                  numberOfReviews: product.reviews.total || 0,
                  starRating: product.reviews.averageScore,
                  screenReaderOnlyText: '',
                },
                swatch: swatchProps
                  ? {
                      shape: SwatchShape.CIRCLE,
                      size: SwatchSize.Small,
                      colours: swatchProps,
                      i18nText: {
                        unavailableText: i18nText.swatchTooltipUnavailable,
                        closeButtonText: i18nText.swatchTooltipCloseButton,
                      },
                    }
                  : undefined,
                marketedSpecialOffer: productParent?.marketedSpecialOffer
                  ? {
                      i18nText,
                      title:
                        productParent.marketedSpecialOffer.title?.content[0]
                          .content,
                      description:
                        productParent.marketedSpecialOffer.description
                          ?.content[0].content || '',
                      landingPageLink:
                        (productParent.marketedSpecialOffer.landingPageLink && {
                          text:
                            productParent.marketedSpecialOffer.landingPageLink
                              .text,
                          url:
                            productParent.marketedSpecialOffer.landingPageLink
                              .url,
                          openExternally:
                            productParent.marketedSpecialOffer.landingPageLink
                              .openExternally || false,
                          noFollow:
                            productParent.marketedSpecialOffer.landingPageLink
                              .noFollow || false,
                        }) ||
                        undefined,
                      loading: false,
                      onlyDisplayOfferBadge: true,
                      shouldRemoveScrollLock: false,
                    }
                  : undefined,
                brand: {
                  name: productParent?.product?.brand?.name,
                  imageUrl: productParent?.product?.brand?.imageUrl,
                },
                content: productParent?.content,
                powerReviewGroups: getPowerReviewGroups(productParent?.content),
              }
            },
          )
        : []

    const productsShowcaseProps: ProductsShowcaseProps = {
      showPdpLinkWhenOutOfStock,
      productList: productBlocks.length > 0 ? productBlocks : productListData,
      alignText: 'left',
      leftSideContent:
        props.headline && props.text
          ? {
              title: { text: props.headline, useH1: props.useH1 || undefined },
              subtitle: { text: props.text },
              ctaButton:
                props.ctaOne && props.ctaOneURL
                  ? {
                      text: props.ctaOne,
                      link: props.ctaOneURL,
                      ariaLabel: props.ctaOneAriaLabel || undefined,
                    }
                  : undefined,
            }
          : undefined,
      orientation: Orientation.HORIZONTAL,
      carouselView: false,
      contentAlignments: {
        gridColumns: 12,
        gridItemColumns: {
          firstItem: { colStart: 1, colSpan: [12, 12, 12, 8], rowStart: 1 },
          secondItem: {
            colStart: [1, 1, 1, 9],
            colSpan: 12,
            rowStart: [2, 2, 2, 1],
          },
        },
        productContent: {
          titleAlignment: 'left',
          display: 'flex',
          directions: ['column', 'row'],
          style: {
            width: '50%',
            sidePadding: 1,
          },
        },
      },
    }

    return <StyledProductsShowcase {...productsShowcaseProps} />
  }

  if (props.__typename === 'promoproductslider') {
    const theme = useTheme()
    const sessionSettings = useHorizonSessionSettings()

    const { data } = useQuery<
      {
        productsShowcaseList: Omit<ProductList, 'products'> & {
          products: (Product & { inStock: boolean })[]
        }
      },
      ProductsShowcaseArgs
    >(PRODUCTS_SHOWCASE_LIST_QUERY, {
      variables: {
        ...sessionSettings,
        path: props.pagePath,
        id: props.id,
        input: {
          ...sessionSettings,
          facets: [],
          sort: ProductSort.Relevance,
          limit: 12,
          offset: 0,
        },
      },
    })

    const queryProductList = data?.productsShowcaseList?.products

    if (!props.featuredImage || !queryProductList) {
      return null
    }

    const productListData: ProductBlockProps[] = queryProductList.map(
      (product) => {
        const productPrice =
          product.defaultVariant?.price || product.cheapestVariant?.price

        return {
          sku: product.sku.toString(),
          title: { value: product.title },
          url: product.url,
          price: productPrice?.price.displayValue
            ? {
                price: {
                  defaultPrice: productPrice.price.displayValue,
                  minPrice: product.cheapestVariant?.price?.price.displayValue,
                  maxPrice:
                    product.mostExpensiveVariant?.price?.price.displayValue,
                },
                rrp: {
                  defaultPrice: getRrp(productPrice),
                  minPrice:
                    product.cheapestVariant?.price?.rrp &&
                    getRrp(product.cheapestVariant.price),
                  maxPrice:
                    product.mostExpensiveVariant?.price?.rrp &&
                    getRrp(product.mostExpensiveVariant.price),
                },
                rrpText: i18nText.rrpText,
              }
            : undefined,
          image: {
            urls: { largeProduct: product.images[0].largeProduct },
            isAmp: false,
          },
          hoverImage:
            !hideProductListImageRollover && product.images.length > 1
              ? {
                  urls: { largeProduct: product.images[1].largeProduct },
                  isAmp: false,
                }
              : undefined,
          review: product.reviews && {
            numberOfReviews: product.reviews.total || 0,
            starRating: product.reviews.averageScore,
            screenReaderOnlyText: '',
          },
          marketedSpecialOffer:
            (product.marketedSpecialOffer && {
              i18nText,
              title: product.marketedSpecialOffer.title?.content[0].content,
              description:
                product.marketedSpecialOffer.description?.content[0].content ||
                '',
              landingPageLink:
                (product.marketedSpecialOffer.landingPageLink && {
                  text: product.marketedSpecialOffer.landingPageLink.text,
                  url: product.marketedSpecialOffer.landingPageLink.url,
                  openExternally:
                    product.marketedSpecialOffer.landingPageLink
                      .openExternally || false,
                  noFollow:
                    product.marketedSpecialOffer.landingPageLink.noFollow ||
                    false,
                }) ||
                undefined,
              loading: false,
              onlyDisplayOfferBadge: true,
              shouldRemoveScrollLock: false,
            }) ||
            undefined,
          inStock: product.inStock,
        }
      },
    )

    const productsShowcaseProps: ProductsShowcaseProps = {
      showPdpLinkWhenOutOfStock,
      hideProductShowCaseMarketedSpecialOfferBadge,
      image: {
        source: props.featuredImage,
        imageUrl: props.imageURL || undefined,
        alt: props.imageAltText || '',
      },
      productList: productListData,
      displayProductButton: true,
      title: props.widgetTitle ? { text: props.widgetTitle } : undefined,
      subtitle: props.widgetSubtitle
        ? { text: props.widgetSubtitle }
        : undefined,
      orientation: (props.orientation as Orientation) || Orientation.HORIZONTAL,
      carouselView: true,
      contentAlignments: {
        gridColumns: 12,
        productContent: {
          titleAlignment: theme.widget.productsShowcase.titleAlignment,
          directions: ['column'],
        },
      },
    }

    return <StyledProductsShowcase {...productsShowcaseProps} />
  }

  return null
}
